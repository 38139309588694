<template>
  <div class="edit-post">
    <v-menu
      rounded
      origin="center center"
      bottom
      left
      transition="scale-transition"
      :offset-y="offset"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="no-background-hover"
          :ripple="false"
          depressed
          dark
          dense
          color="black"
          icon
          elevation="0"
          v-bind="attrs"
          v-on="on"
          @click="clickOpctionsBtn(postCategory)"
        >
          <v-img
            class="posts-icon"
            src="@/assets/img/edit.svg"
            max-width="34"
            max-height="48"
          />
        </v-btn>
      </template>

      <v-list
        class="edit-post-list"
        flat
        elevation="0"
        dense
      >
        <v-list-item-group>
          <a
            class="list-item-title disabled"
            @click="clickEditBtn"
          >
            {{ $t('components.edit_post.edit') }}
          </a>
          <a
            class="list-item-title"
            :class="!enableDeleteBtn && 'disabled'"
            @click="showPostDelete"
          >
            {{ $t('components.edit_post.delete') }}
          </a>
          <a
            v-if="showTodoBtn"
            class="list-item-title"
            @click="moveToTodo"
          >
            {{ $t('components.edit_post.todos') }}
          </a>
          <a
            v-if="showDoneBtn"
            class="list-item-title"
            @click="donePost"
          >
            {{ $t('components.edit_post.done') }}
          </a>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import trackEvent from 'mixpanel-browser';
import { logEvent } from '../../plugins/firebase';

export default {
  name: 'EditPost',
  props: {
    postId: {
      type: Number,
      default: null,
    },
    enableDeleteBtn: {
      type: Boolean,
      default: false,
    },
    showTodoBtn: {
      type: Boolean,
      default: false,
    },
    showDoneBtn: {
      type: Boolean,
      default: false,
    },
    postCategory: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    offset: true,
  }),
  methods: {
    ...mapActions([
      'todos',
      'done',
    ]),
    translateCategory(category) {
      if (category === 'todo') return 'Tarefas';
      return category === 'improve' ? 'Melhorar' : 'Bom';
    },
    moveToTodo() {
      this.todos({ id: this.postId });
    },
    donePost() {
      this.done({ id: this.postId });
    },
    showPostDelete() {
      if (this.enableDeleteBtn) this.$emit('showPopUp');
    },
    clickEditBtn() {
      logEvent('clicou_editar_post', { coluna: this.postCategory });
      const category = this.translateCategory(this.postCategory);
      trackEvent.track('clicou_editar_post', { Categoria: category });
    },
    clickOpctionsBtn() {
      const category = this.translateCategory(this.postCategory);
      trackEvent.track('visualizou_opcoes_post', { Categoria: category });
    },
  },

};
</script>

<style lang="scss" scoped>
  .no-background-hover::before {
    background-color: transparent !important;
  }

  .edit-post-list {
    width: 120px;
    text-align: center;
    background: $card-background;
    box-shadow: 0px 4px 8px $edit-post-shadow;

    .list-item-title {
      font-family: NunitoBold;
      padding: 8px;
      font-size: 14px;
      display: block;
      color: $edit-post-text;

      &.disabled {
        color: $disabled;
      }
    }
  }
</style>
